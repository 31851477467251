<template>
	<div class="home">
		<el-carousel trigger="click" arrow="always" indicator-position="none">
			<el-carousel-item v-for="(item, index) in main_swipers" :key="index">
				<div class="carousel_item_img">
					<img :src="item.url" alt="" />
				</div>
			</el-carousel-item>
		</el-carousel>
		<div class="content">
			<div class="content_item content_item_1">
				<div class="content_item_nav">
					<div class="content_item_title">
						<span class="content_item_title_1">{{ $t('title.recruit') }}</span>
						<span class="content_item_title_2">JOIN US</span>
					</div>
					<div @click="goHire()" class="content_item_button">MORE+</div>
				</div>
				<div class="joinus">
					<img class="joinus_logo" src="@/assets/img/joinus.jpeg" alt="" />
					<div class="joinus_box">
						<div v-for="(item, index) in recruit" :key="index" @click="goHire(item)" class="joinus_item" :style="{'textAlign': index % 2 == 0 ? 'left' : 'right'}">
							{{ item.title }}
						</div>
					</div>
				</div>
			</div>
			<div class="content_item">
				<div class="content_item_nav">
					<div class="content_item_title">
						<span class="content_item_title_1">{{ $t('title.news') }}</span>
						<span class="content_item_title_2">PRESS RELEASE</span>
					</div>
					<div @click="goNewsList()" class="content_item_button">MORE+</div>
				</div>
				<div class="news">
					<div v-for="(item, index) in news" :key="index" @click="goNews(item)" class="news_item">
						<div class="news_title">{{ item.title }}</div>
						<div class="news_date">{{ item.date }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			main_swipers: [{ url: require('@/assets/img/benner_1.jpg') }],
			recruit: [], // 招聘
			news: [] // 新闻
		}
	},
	created() {
		this.basePage()
	},
	methods: {
		basePage() {
			// 页面初始化
			switch (this.$i18n.locale) {
				case 'zh': // 中
					this.getZhDetail()
					break
				case 'en': // 英
					this.getEnDetail()
					break
			}
		},
		getZhDetail() {
			this.recruit = this.$config.hireListZH
			let news = []
			for (let i = 0;i < 4;i++) {
				news.push(this.$config.newsListZH[i])
			}
			this.news = news
		},
		getEnDetail() {
			this.recruit = this.$config.hireListEN
			this.news = this.$config.newsListEN
		},
		goHire(item) {
			// 职业详情
			this.$router.push({ path: '/hire', query: { id: item ? item.id : ''} })
		},
		goNewsList() {
			// 新闻列表
			this.$router.push({ path: '/newsList' })
		},
		goNews(item) {
			// 新闻详情
			this.$router.push({ path: '/news', query: { id: item._id, type: 'home' } })
		}
	}
}
</script>
<style scoped>
.content {
	display: flex;
	flex-wrap: wrap;
	margin: 0 auto;
	padding: 30px 0 100px 0;
	width: 1000px;
}
.content_item {
	width: 58%;
}
.content_item_1 {
	flex: 1;
	margin-right: 80px;
}
.content_item_nav {
	display: flex;
	align-items: center;
	padding-bottom: 20px;
	margin-bottom: 25px;
	border-bottom: 1px solid #e9e9e9;
}
.content_item_title {
	position: relative;
	flex: 1;
	padding-left: 20px;
	box-sizing: border-box;
}
.content_item_title::after {
	content: '';
	position: absolute;
	top: 0;
	left: 0;
	width: 4px;
	height: 100%;
	border-radius: 3px;
	background-color: #ff6600;
}
.content_item_title_1 {
	font-size: 24px;
	color: #14a6a3;
}
.content_item_title_2 {
	margin-left: 10px;
	font-size: 15px;
	color: #e9e9e9;
}
.content_item_button {
	margin-left: 20px;
	width: 80px;
	line-height: 30px;
	text-align: center;
	background: #14a6a3;
	color: #fff;
	font-size: 14px;
	cursor: pointer;
}
.joinus {
	display: flex;
	align-items: center;
}
.joinus_logo {
	margin-right: 50px;
	width: 80px;
}
.joinus_box {
	flex: 1;
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}
.joinus_item {
	margin: 9px 0;
	cursor: pointer;
	text-align: right;
	font-size: 18px;
}
.news_item {
	display: flex;
	align-items: center;
	overflow: hidden;
	color: #999;
}
.news_title {
	flex: 1;
	line-height: 30px;
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
}
.news_date {
	margin-left: 20px;
}
@media screen and (max-width: 1000px) {
	.content {
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
	.content_item {
		width: 80%;
	}
	.content_item_1 {
		margin-right: 0;
		margin-bottom: 40px;
	}
	.joinus_item {
		width: 50%;
	}
}
@media screen and (max-width: 600px) {
	.content_item {
		width: 90%;
	}
}
@media screen and (max-width: 400px) {
	.content_item_title_1{
		font-size: 20px;
	}
	.content_item_title_2{
		font-size: 13px;
	}
	.content_item_button{
		width: 70px;
		line-height: 25px;
	}
}
@media screen and (max-width: 350px) {
	.joinus_item{
		font-size: 15px;
	}
	.news_title{
		font-size: 14px;
	}
}
</style>
<style>
.el-carousel__container,
.carousel_item_img img {
	height: 25vw;
}
.carousel_item_img {
	display: flex;
	justify-content: center;
}
.carousel_item_img img {
	width: auto;
}
@media screen and (max-width: 1000px) {
	.el-carousel__container,
	.carousel_item_img img {
		height: 35vw;
	}
}
@media screen and (max-width: 600px) {
	.el-carousel__container,
	.carousel_item_img img {
		height: 40vw;
	}
}

</style>
